import { RichTextContent } from '@/components/design/RichTextContent'
import { ButtonLink } from '@/components/ui/ButtonLinks'
import { contentfulImageLoader } from '@/utils/ImageLoaders'
import Image from 'next/legacy/image'
import { Maybe } from 'types/generated/contentful-types'

const renderTextContent = (
  textContent,
  heading,
  teaserCopy,
  title,
  borders,
  icon
) => {
  if (textContent) {
    return (
      <>
        <div
          className={`${
            borders ? 'border-t border-b border-darkShell py-6' : ''
          }`}
        >
          {icon && (
            <div className="w-10 mx-auto">
              <Image
                loader={() =>
                  contentfulImageLoader({
                    src: icon?.url ?? '',
                    width: 40,
                    height: 40,
                    quality: 90,
                    fit: 'thumb',
                  })
                }
                src={icon?.url ?? ''}
                alt={icon?.description || `Icon`}
                layout="responsive"
                width={40}
                height={40}
                sizes="40vw"
              />
            </div>
          )}
          {textContent && (
            <div className="m-auto page-blocks-page-content headerBlockRichTextSpecialCase lg:max-w-3xl">
              <RichTextContent richText={textContent.json} />
            </div>
          )}
        </div>
      </>
    )
  } else {
    return (
      <>
        {title && heading === 'h1' && (
          <h1 className="m-auto mb-6 lg:mb-8 fora-text-h1 lg:max-w-2xl">
            {title}
          </h1>
        )}
        {title && heading === 'h2' && (
          <h2 className="m-auto mb-6 lg:mb-8 fora-text-h2 lg:max-w-2xl">
            {title}
          </h2>
        )}
        {title && heading === 'h3' && (
          <h3 className="m-auto mb-6 lg:mb-8 fora-text-h3 lg:max-w-2xl">
            {title}
          </h3>
        )}
        {title && heading === 'h4' && (
          <h4 className="m-auto mb-6 lg:mb-8 fora-text-h4 lg:max-w-2xl">
            {title}
          </h4>
        )}
        {teaserCopy && (
          <div className="m-auto page-blocks-page-content lg:max-w-2xl">
            <RichTextContent richText={teaserCopy.json} />
          </div>
        )}
      </>
    )
  }
}

export type HeaderBlockProps = {
  title: string
  titleStyle?: Maybe<string>
  textContent?: {
    json: any
  }
  teaserCopy?: {
    json: any
  }
  customTeaser?: string
  ctaText?: string
  ctaUrl?: string
  headerClass?: string
  sectionId?: string
  border?: boolean
  heading?: 'h1' | 'h2' | 'h3' | 'h4'
  paddingBottom?: boolean
  urlTarget?: '_self' | '_blank'
  ctaTheme?: 'primary' | 'secondary'
  borders?: boolean
  footNote?: string
  icon?: any
  secondaryCtaText?: string
  secondaryCtaUrl?: string
  secondaryUrlTarget?: '_self' | '_blank'
}

export default function HeaderBlock({
  title,
  textContent,
  teaserCopy,
  ctaText,
  ctaUrl,
  sectionId,
  customTeaser,
  heading = 'h2',
  border,
  paddingBottom = false,
  urlTarget,
  headerClass,
  ctaTheme,
  borders = false,
  footNote,
  icon,
  secondaryCtaText,
  secondaryCtaUrl,
  secondaryUrlTarget,
}: HeaderBlockProps): JSX.Element {
  const headerBlockClass = `${
    border ? 'mb-4 lg:pb-8 pt-12 lg:pt-22 border-t border-darkShell' : ''
  } ${headerClass ? headerClass : ''}`
  return (
    <section id={sectionId ?? ''} className={headerBlockClass}>
      <div
        className={`${
          paddingBottom ? 'pb-8 lg:pb-14 ' : ''
        } m-auto text-center`}
        data-name="header-block"
      >
        {renderTextContent(
          textContent,
          heading,
          teaserCopy,
          title,
          borders,
          icon
        )}
        {customTeaser && (
          <div className="m-auto page-blocks-page-content lg:max-w-xl">
            <div className="global-richtext">
              <p>{customTeaser}</p>
            </div>
          </div>
        )}
        {footNote && (
          <div className="m-auto mt-4 lg:max-w-xl">
            <p className="font-serif text-xs italic font-semibold md:text-sm text-darkStone">
              {footNote}
            </p>
          </div>
        )}
        <div className="flex justify-center gap-4">
          {ctaText && ctaUrl && (
            <div className="mt-8">
              <ButtonLink
                href={ctaUrl}
                target={urlTarget || '_self'}
                text={ctaText}
                theme={ctaTheme || 'secondary'}
              />
            </div>
          )}
          {secondaryCtaText && secondaryCtaUrl && (
            <div className="mt-8">
              <ButtonLink
                href={secondaryCtaUrl}
                target={secondaryUrlTarget || '_self'}
                text={secondaryCtaText}
                theme="transparent"
              />
            </div>
          )}
        </div>
        <div />
      </div>
    </section>
  )
}
